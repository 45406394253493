import { Query } from 'react-apollo';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Loading from 'components/atoms/Loading';
import orderQuery from 'graphql/queries/orderForDetails.graphql';
import Button from 'components/atoms/Button';
import { Dd, Dl, Dt } from 'components/atoms/Dl';
import Table from 'components/pages/CartPage/Table';
import Summary from './Summary';

const StyledLoading = styled(Loading)`
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  margin: 2em 0;
`;

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const StyledSummary = styled(Summary)`
  text-align: right;
  margin-top: 0.5em;
`;

const Pre = styled.pre`
  white-space: normal;
`;

const StyledDl = styled(Dl)`
  margin-bottom: 2em;
`;

const OrderDetails = ({ orderId }) => {
  return (
    <Query
      query={orderQuery}
      variables={{
        orderId,
      }}
      notifyOnNetworkStatusChange
    >
      {({ loading, data, error, refetch, networkStatus }) => {
        if (loading || networkStatus === 4) {
          return <StyledLoading />;
        }

        if (error) {
          return (
            <Wrapper>
              <p>Произошла ошибка при загрузке истории статусов</p>
              <Button
                primary
                onClick={() => {
                  refetch();
                }}
              >
                Повторить загрузку
              </Button>
            </Wrapper>
          );
        }

        return (
          <Wrapper>
            <StyledDl>
              <Dt>Имя заказчика</Dt>
              <Dd>
                {`${data.order.lastName || ''} ${data.order.firstName || ''} ${
                  data.order.middleName || ''
                }`.trim()}
              </Dd>
              {data.order.phone && (
                <>
                  <Dt>Номер телефона</Dt>
                  <Dd>{data.order.phone}</Dd>
                </>
              )}
              {data.order.email && (
                <>
                  <Dt>E-mail</Dt>
                  <Dd>{data.order.email}</Dd>
                </>
              )}
              {data.order.deliveryMethod ? (
                <>
                  <Dt>Способ доставки</Dt>
                  <Dd>{data.order.deliveryMethod}</Dd>
                </>
              ) : (
                <>
                  <Dt>Способ доставки</Dt>
                  <Dd>Самовывоз</Dd>
                </>
              )}
              {data.order.comment && (
                <>
                  <Dt>Комментарий</Dt>
                  <Dd>
                    <Pre>{data.order.comment}</Pre>
                  </Dd>
                </>
              )}
              <Dt>Дата заказа</Dt>
              <Dd>{dayjs(data.order.createdAt).format('DD MMM YYYY HH:mm')}</Dd>
            </StyledDl>

            <Table cartItems={data.order.items} readOnly />
            <StyledSummary order={data.order} />
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default OrderDetails;
