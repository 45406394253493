import ProfileTemplate from 'components/templates/ProfileTemplate';
import Button from 'components/atoms/Button';
import OrderCard from './OrderCard';

const ProfileOrdersPage = ({ currentUser, fetchMore }) => (
  <ProfileTemplate>
    {currentUser.ordersConnections.edges.map((orderConnectionEdge) => (
      <OrderCard key={orderConnectionEdge.node.id} order={orderConnectionEdge.node} />
    ))}
    {currentUser.ordersConnections.pageInfo.hasNextPage && (
      <Button
        neutral
        inline
        onClick={() => {
          fetchMore({
            variables: {
              cursor: { id: currentUser.ordersConnections.pageInfo.endCursor },
              skip: 1,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const { edges: newEdges, pageInfo } = fetchMoreResult.currentUser.ordersConnections;

              return newEdges.length
                ? {
                    // Put the new orders at the end of the list and update `pageInfo`
                    // so we have the new `endCursor` and `hasNextPage` values
                    currentUser: {
                      ...previousResult.currentUser,
                      ordersConnections: {
                        __typename: previousResult.currentUser.ordersConnections.__typename,
                        edges: [...previousResult.currentUser.ordersConnections.edges, ...newEdges],
                        pageInfo,
                      },
                    },
                  }
                : previousResult;
            },
          });
        }}
      >
        Показать ещё
      </Button>
    )}
  </ProfileTemplate>
);

export default ProfileOrdersPage;
