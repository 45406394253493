import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 1.3em;
`;

const Summary = ({ order, className }) => (
  <Wrapper className={className}>
    <strong>{order.summary.number} товар(ов/а)</strong> на сумму {order.summary.total.toFixed(2)} ₽
  </Wrapper>
);

export default Summary;
