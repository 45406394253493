import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import CommentIcon from 'react-icons/lib/fa/comment';
import HistoryIcon from 'react-icons/lib/fa/history';
import Label from 'components/atoms/Label';
import Button from 'components/atoms/Button';
import Modal from 'components/atoms/Modal';
import OrderStatusRecords from './OrderStatusRecords';
import OrderDetails from './OrderDetails';
import Summary from './Summary';

const Wrapper = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  margin-bottom: 1em;
  padding: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 1em;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: -0.2rem -0.2em 0;
`;

const Title = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0.2rem;
`;

const StyledLabel = styled(Label)`
  margin: 0.2rem;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
`;

const RightGroup = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pre = styled.pre`
  white-space: normal;
`;

const SummaryRow = styled.div`
  margin: 1em -0.2rem -0.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const StyledSummary = styled(Summary)`
  margin: 0.2rem;
`;

const DetailsButton = styled(Button)`
  margin: 0.2rem;
`;

const OrderCard = ({ order }) => {
  const [isMessageModalShown, setMessageModalState] = useState(false);
  const [isHistoryModalShown, setHistoryModalState] = useState(false);
  const [isDetailsModalShown, setDetailsModalState] = useState(false);

  function handleMessageModalShow() {
    setMessageModalState(true);
  }

  function handleMessageModalHide() {
    setMessageModalState(false);
  }

  function handleHistoryModalShow() {
    setHistoryModalState(true);
  }

  function handleHistoryModalHide() {
    setHistoryModalState(false);
  }

  function handleDetailsModalShow() {
    setDetailsModalState(true);
  }

  function handleDetailsModalHide() {
    setDetailsModalState(false);
  }

  return (
    <Wrapper>
      <FirstRow>
        <Title>Заказ №{order.number}</Title>
        {(order.statuses.length === 0 || order.statuses[0].status.name !== '') && (
          <RightGroup>
            {order.statuses.length > 0 ? (
              <StyledLabel color="neutral4">{order.statuses[0].status.name}</StyledLabel>
            ) : (
              <StyledLabel color="neutral4">Ожидает обработки</StyledLabel>
            )}
            {order.statuses.length > 0 && order.statuses[0].message && (
              <>
                <StyledButton neutral title="Комментарий" onClick={handleMessageModalShow}>
                  <CommentIcon />
                </StyledButton>
                <Modal
                  show={isMessageModalShown}
                  title={`Комментарий к заказу №${order.number}`}
                  actions={[
                    <Button neutral inline onClick={handleMessageModalHide}>
                      Закрыть
                    </Button>,
                  ]}
                  onHide={handleMessageModalHide}
                >
                  <p>
                    Менеджер магазина &quot;Золотая Камея&quot; оставил следующий комментрий для
                    данного заказа:
                  </p>
                  <Pre>{order.statuses[0].message}</Pre>
                </Modal>
              </>
            )}
            <StyledButton neutral title="История" onClick={handleHistoryModalShow}>
              <HistoryIcon />
            </StyledButton>
            <Modal
              show={isHistoryModalShown}
              title={`История статусов для заказа №${order.number}`}
              actions={[
                <Button neutral inline onClick={handleHistoryModalHide}>
                  Закрыть
                </Button>,
              ]}
              onHide={handleHistoryModalHide}
            >
              <OrderStatusRecords orderId={order.id} />
            </Modal>
          </RightGroup>
        )}
      </FirstRow>
      <Subtitle>{dayjs(order.createdAt).format('DD MMM YYYY HH:mm')}</Subtitle>
      <SummaryRow>
        <StyledSummary order={order} />
        <DetailsButton neutral onClick={handleDetailsModalShow}>
          Детали заказа
        </DetailsButton>
        <Modal
          show={isDetailsModalShown}
          title={`Детали заказа №${order.number}`}
          actions={[
            <Button neutral inline onClick={handleDetailsModalHide}>
              Закрыть
            </Button>,
          ]}
          onHide={handleDetailsModalHide}
        >
          <OrderDetails orderId={order.id} />
        </Modal>
      </SummaryRow>
    </Wrapper>
  );
};

export default OrderCard;
