import { Query } from 'react-apollo';
import styled from 'styled-components';
import dayjs from 'dayjs';
import orderQuery from 'graphql/queries/orderForStatusRecords.graphql';
import Loading from 'components/atoms/Loading';
import Button from 'components/atoms/Button';
import Table from 'components/atoms/Table/Table';
import Thr from 'components/atoms/Table/Thr';
import Td from 'components/atoms/Table/Td';
import Tr from 'components/atoms/Table/Tr';

const StyledLoading = styled(Loading)`
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  margin: 2em 0;
`;

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const ColNameForMobile = styled.strong`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }
`;

const Pre = styled.pre`
  white-space: normal;
`;

const OrderStatusRecords = ({ orderId }) => (
  <Query
    query={orderQuery}
    variables={{
      orderId,
    }}
    notifyOnNetworkStatusChange
  >
    {({ loading, data, error, refetch, networkStatus }) => {
      if (loading || networkStatus === 4) {
        return <StyledLoading />;
      }

      if (error) {
        return (
          <Wrapper>
            <p>Произошла ошибка при загрузке истории статусов</p>
            <Button
              primary
              onClick={() => {
                refetch();
              }}
            >
              Повторить загрузку
            </Button>
          </Wrapper>
        );
      }

      return (
        <Wrapper>
          <Table>
            <Thr>
              <Td>Статус</Td>
              <Td>Сообщение</Td>
              <Td>Дата</Td>
            </Thr>
            {data.order.statuses.length === 0 && (
              <Tr>
                <Td>
                  <ColNameForMobile>Статус:</ColNameForMobile> Ожидает обработки
                </Td>
                <Td>
                  <ColNameForMobile>Сообщение:</ColNameForMobile> —
                </Td>
                <Td>
                  <ColNameForMobile>Дата:</ColNameForMobile>{' '}
                  {dayjs(data.order.createdAt).format('DD MMM YYYY HH:mm')}
                </Td>
              </Tr>
            )}
            {data.order.statuses.map((statusRecord) => (
              <Tr key={statusRecord.id}>
                <Td>
                  <ColNameForMobile>Статус:</ColNameForMobile> {statusRecord.status.name}
                </Td>
                <Td>
                  <ColNameForMobile>Сообщение:</ColNameForMobile>{' '}
                  {statusRecord.message ? <Pre>{statusRecord.message}</Pre> : '—'}
                </Td>
                <Td>
                  <ColNameForMobile>Дата:</ColNameForMobile>{' '}
                  {dayjs(statusRecord.createdAt).format('DD MMM YYYY HH:mm')}
                </Td>
              </Tr>
            ))}
          </Table>
        </Wrapper>
      );
    }}
  </Query>
);

export default OrderStatusRecords;
